import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid';
import PurplebeanLogo from '../../images/brands/purplebean-logo.png';
import ReturnArrow from '../../images/return-arrow.svg';

const Purplebean= () => (
  <Layout>
    <Grid container
          className="link-list"
    >
      <Grid item xs={4}>
        <div>
          <Link to="/projects/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/about/">About</Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/contact/">Contact</Link>
        </div>
      </Grid>
    </Grid>
      <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={8}>
        <div className='app-intro'>
          <Link to="projects/">Projects</Link>
          <Grid item xs={12}>
                 <span>
              <Link className="arrow" to="/projects/skicentrummoser">&#x2039; </Link>
            </span>
            <h2 className="inline"> Purplebean </h2>
            <span >
              <Link className="arrow" to="/projects/praktijkbalke">&#x203A;</Link>
            </span>
          </Grid>
          <img src={ PurplebeanLogo } className="project-detail"  alt="Skills" />
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <h3>Summary</h3>
              <p>Purplebean was the brand I used to design and create webpages for small businesses. Because my business focus changed, I decided not to use this brand anymore.</p>

            </Grid>
            <Grid item md={6}>
              <h3>Projects</h3>
              <p className="subcontent">Cafe Thijssen, ActionPact, PraktijkBalke, Skicentrummoser, Neckwork(gone), DeFysioman and several small wordpress sites.</p>
              <h3>Period</h3>
              <p className="subcontent">2015 - 2018</p>
            </Grid>
          </Grid>
        </div>
      </Grid>
  </Layout>
)

export default Purplebean;
